import React from 'react';
import SectionTitle from 'components/sections/title';
import styled from 'styled-components';
import Img from 'gatsby-image';
import devProcessImg from 'images/DevProcessTransparent.png';

const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
`;

const LI = ({ children }) => (
  <li style={{ fontSize: '1.2rem' }}>
    <i className="mdi mdi-check-circle-outline icon-sm text-success mr-2"></i>
    {children}
  </li>
);

const DevProcessSection = () => (
  <section className="section bg-light">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6">
          <h3 className="title mb-3">
            Our {' '}
            <span className="text-primary">Development Process</span>
            {' '} is based on the industry best practices and time-proved approaches.
          </h3>
          <h4 className="title mt-5">
            Usual steps of the process:
          </h4>
          <ul className="list-unstyled feature-list">
            <LI>Meet the client and discuss their projects.</LI>
            <LI>
              Understanding requirements and conducting the initial analysis.
            </LI>
            <LI>Determine the scope and budget.</LI>
            <LI>Plan. Design. Manage risks.</LI>
            <LI>Formalize the backlog. </LI>
            <LI>Build the functionality. Test. </LI>
            <LI>Do the Demo. </LI>
            <LI>Gather feedback and analyse it. </LI>
            <LI>Release!</LI>
            <LI>Monitor and Provide support for shipped features and products.</LI>
          </ul>
        </div>
        <div className="col-12 col-sm-6">
          <div className="w-100 text-center">
            <img
              src={devProcessImg}
              alt="development process"
              style={{ height: '100vh' }}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default DevProcessSection;