import React from 'react';
import Layout from 'components/layout';
import styled from "styled-components";
import Shape from "components/shape";
import Footer from 'components/footer';
import DevProcessSection from 'components/sections/processesPage/devProcess';
import QualitiesSection from 'components/sections/processesPage/qualities';

const HeadText = ({ children }) => <h1 className="text-white mr-4">{children}</h1>;

// backgroundImage: 'linear-gradient(15deg, #80d0c7 0%, #13547a 100%)',
// 'radial-gradient(circle at 15% 0%, #80d0c7 0%,  #13547a 100%)',

const HeroSection = ({ title, text }) => (
  <section
    className="section-xl full-height bg-home overflow-hidden w-100"
    style={{
      backgroundColor: '#ffffff',
      backgroundImage:
        'radial-gradient(100% 200% at 50% 150%, #80d0c7 0%,  #13547a 100%)',
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="text-center title-heading">
              <HeadText>Agile. Iterative. Predictable.</HeadText>
              <HeadText>Transparent. Efficient.</HeadText>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Processes = () => (
  <Layout>
    <HeroSection />
    <Shape color="#f8f9fc" />
    <DevProcessSection />
    <QualitiesSection />
    <Footer />
  </Layout>
);

export default Processes;