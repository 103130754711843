import React from 'react';
import SectionTitle from 'components/sections/title';
import styled from 'styled-components';

const CardIcon = styled.i`
  && {
    font-size: 10rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    margin: 0 auto;
    opacity: 0.1;
    height: 100%;
    width: 100%;
  }
`;

const Highlighted = styled.span`
  color: #6dc77a;
`;

const Card = ({ children }) => (
  <div
    className="shadow-lg text-dark card rounded-circle text-center"
    style={{ width: '15rem', height: '15rem', padding: '2em', margin: '1em auto' }}
  >
    <CardIcon className="mdi mdi-check text-success mb-0"></CardIcon>
    <span className="card-title" style={{ margin: 'auto' }}>
      {children}
    </span>
  </div>
);

const QualitiesSection = () => (
  <section className="section-sm bg-light mt-0 pt-0">
    <div className="container">
      <SectionTitle>
        With that process in place, we gain the benefits
      </SectionTitle>
      <div className="row mt-4">
        <div className="col-12">
          <h4 className="title">
            {/* Находясь в индустрии столько лет и постоянно улучшаясь, теперь мы сейчас обладаем следующими качествами */}
            {/* Through the years in the industry and constant self-improvement we now reached
            the quality and state */}
            {/* Through the years in the industry and constant self-improvement we are now practicing */}
          </h4>
        </div>
      </div>
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-sm-12 col-md-3">
          <Card>
            <Highlighted>Shipping</Highlighted> <br /> early and often
          </Card>
        </div>
        <div className="col-12 col-sm-12 col-md-3">
          <Card>
            <Highlighted>Shipping</Highlighted> <br />
            only the necessary scope
          </Card>
        </div>
        <div className="col-12 col-sm-12 col-md-3">
          <Card>
            <Highlighted>Adapting</Highlighted>
            <br /> to business needs quickly
          </Card>
        </div>
      </div>
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-3">
          <Card>
            Receive <br /> <Highlighted>pleasure</Highlighted> from our job
          </Card>
        </div>
        <div className="col-12 col-md-3">
          <Card>
            Keeping <br /> our clients{' '}
            <Highlighted>enriched and happy</Highlighted>
          </Card>
        </div>
      </div>
    </div>
  </section>
);

export default QualitiesSection;